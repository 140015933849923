import React from "react"

import Layout from "../components/layout"


import kov from "../images/kovka1.png"
import kov1 from "../images/kovka8.png"
import kov2 from "../images/kovka3.png"
import kov3 from "../images/kovka4.png"
import kov4 from "../images/kovka5.png"
import kov5 from "../images/kovka6.png"
import kov6 from "../images/kovka9.png"

const Kovka = () => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            КОВКА
          </h2>
        </div>
      </div>

      {/* <section style={{ height: `70vh` }}>
        <GalleryKovka />
      </section> */}

      <div className="mt-20">
        <div>
          <div className="w-full flex flex-col mb-6 md:flex-row">
            <img
              src={kov}
              alt={"Ковка"}
              style={{
                width: `22rem`,
                height: `15rem`,
                objectFit: `contain`,
              }}
            />
            <div>
              <p
                className="md:ml-6 text-white text-sm md:text-sm lg:text-sm xl:text-sm"
                style={{
                  fontFamily: `Montserrat`,
                }}
              >
                Современные кованые изделия, изготовленные по оригинальным
                эскизам настоящими профессионалами своего дела, выглядят хрупко
                и невесомо, хотя на самом деле отличаются долговечностью и
                прочностью. Ассортимент нашей продукции включает в себя кованые
                ворота, заборы и ограждения, решётки, навесы, беседки. Кованые
                изделия изготавливается по индивидуальным чертежам. Производство
                продукции в строгом соответствии пожеланий заказчика. Идеально
                подходит для нестандартных объектов.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
        <img
          src={kov1}
          alt={"Ковка"}
          // style={{
          //   // width: `25rem`,
          //   // height: `auto`,
          //   objectFit: `contain`,
          // }}
        />

        <img
          src={kov2}
          alt={"Ковка"}
          // style={{
          //   // width: `25rem`,
          //   // height: `19.5rem`,
          //   objectFit: `contain`,
          // }}
        />
        <img src={kov3} alt={"Ковка"} />
        <img src={kov4} alt={"Ковка"} />
        <img src={kov5} alt={"Ковка"} />
        <img src={kov6} alt={"Ковка"} />
      </section>
    </Layout>
  )
}

export default Kovka
